// 引入ali-oss
let OSS = require("ali-oss");
// import SparkMD5 from "spark-md5";
import CryptoJS from "crypto-js";

/**
 *  [accessKeyId] {String}：通过阿里云控制台创建的AccessKey。
 *  [accessKeySecret] {String}：通过阿里云控制台创建的AccessSecret。
 *  [bucket] {String}：通过控制台或PutBucket创建的bucket。
 *  [region] {String}：bucket所在的区域， 默认oss-cn-hangzhou。
 */
let client = new OSS({
  region: "oss-ap-southeast-1",
  //云账号AccessKey有所有API访问权限，建议遵循阿里云安全最佳实践，部署在服务端使用RAM子账号或STS，部署在客户端使用STS。
  accessKeyId: "LTAI5tJqStkeUR13RR8k9cd4",
  accessKeySecret: "kaxhhs2TDRD8vXdCotGFgeDH3WZXOB",
  bucket: "sokogate",
});

/**
 *  上传文件，大小不能超过5GB
 * @param {string} ObjName OSS的储存路径和文件名字
 * @param {string} fileUrl 本地文件
 *
 * @retruns Promise
 */
export const put = async (ObjName, fileUrl) => {
  try {
    let result = await client.put(`${ObjName}`, fileUrl);
    // AAA为文件夹， ObjName为文件名字,可以只写名字，就直接储存在 bucket 的根路径
    // console.log(result);
    return result;
  } catch (e) {
    console.log(e);
  }
};

export const upload = async (ObjName, fileUrl) => {
  try {
    let result = await client.multipartUpload(`${ObjName}`, fileUrl, {
      // 进度条的配置项
      progress: function (percentage) {
        // 获取进度条的值
        console.log(percentage);
        // console.log(p * 100)
        this.percent = percentage * 100;
      },
      // 每传输完一个分片 进度条会走一个百分比 不设置的话由于partsize过大,可能导致很长时间进度条都看不到效果
      partSize: 102400, // 指定上传的每个分片的大小，范围为100 KB~5 GB。单个分片默认大小为1 * 1024 * 1024（即1 MB）
    });
    return result;
  } catch (e) {
    console.log(e);
  }
};

export const vodUploader = async (
  UploadAddress,
  UploadAuth,
  VideoId,
  file,
  progress
) => {
  try {
    console.log("file:", file.file);
    const init = {
      secure: true,
      region: "oss-" + UploadAuth.Region,
      accessKeyId: UploadAuth.AccessKeyId,
      accessKeySecret: UploadAuth.AccessKeySecret,
      stsToken: UploadAuth.SecurityToken,
      // expiration: UploadAuth.Expiration,
      bucket: UploadAddress.Bucket,
    };
    console.log("init:", init);
    let vodClient = new OSS(init);
    let result = await vodClient.multipartUpload(
      `${UploadAddress.FileName}`,
      file.file,
      {
        // 进度条的配置项
        progress,
        // 每传输完一个分片 进度条会走一个百分比 不设置的话由于partsize过大 可能导致很长时间进度条都看不到效果
        partSize: 102400, // 指定上传的每个分片的大小，范围为100 KB~5 GB。单个分片默认大小为1 * 1024 * 1024（即1 MB）
      }
    );
    return result;
  } catch (e) {
    console.log(e);
  }
};

export const getFileNameMd5 = (file) => {
  return new Promise(function (resolve, reject) {
    try {
      const types = file.type.split("/");
      const folder = types[0];
      const type = types[1];
      var fileReader = new FileReader();

      //异步执行函数
      fileReader.onload = function (e) {
        // console.log("e.target.result:", e.target.result);
        const md5 = CryptoJS.MD5(
          CryptoJS.enc.Latin1.parse(e.target.result)
        ).toString();
        md5
          ? resolve(`${folder}/${md5}.${type}`)
          : reject(new Error("md5 error"));
      };

      //获取文件二进制数据
      fileReader.readAsDataURL(file);
    } catch (error) {
      reject(error);
    }
  });
};

export const imageUrlToBase64ToFile = (url) => {
  return new Promise(function (resolve, reject) {
    try {
      //一定要设置为let，不然图片不显示
      let image = new Image();

      //解决跨域问题
      image.setAttribute("crossOrigin", "anonymous");
      const fuhao = url.indexOf("?") > -1 ? "&" : "?";
      //image.onload为异步加载
      image.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;
        var context = canvas.getContext("2d");
        context.drawImage(image, 0, 0, image.width, image.height);
        //这里的dataurl就是base64类型
        var dataURL = canvas.toDataURL("image/jpeg"); //使用toDataUrl将图片转换成jpeg的格式,不要把图片压缩成png，因为压缩成png后base64的字符串可能比不转换前的长！
        // console.log("dataURL:", dataURL);

        var bytes = window.atob(dataURL.split(",")[1]); //去掉url的头，并转换为byte

        //处理异常,将ascii码小于0的转换为大于0
        var ab = new ArrayBuffer(bytes.length);
        var ia = new Uint8Array(ab);
        for (var i = 0; i < bytes.length; i++) {
          ia[i] = bytes.charCodeAt(i);
        }

        const blob = new Blob([ab], { type: "image/jpeg" });
        blob ? resolve(blob) : reject(new Error("base64 or blob error"));
      };
      image.onerror = (error) => {
        reject(error);
      };
      image.src = url + fuhao + "weilehuangcuna=" + Math.random();
    } catch (error) {
      reject(error);
    }
  });
};
